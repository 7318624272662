import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

function WeatherIcon({ iconCode }: WeatherIconProps) {
  const size = '3x';
  const yellow = {
    '--fa-primary-color': '#ECD444',
    '--fa-secondary-color': '#ECD444',
    '--fa-primary-opacity': 1,
    '--fa-secondary-opacity': 1,
  } as CSSProperties;

  const yellowWithGray = {
    '--fa-primary-color': '#A4969B',
    '--fa-secondary-color': '#ECD444',
    '--fa-primary-opacity': 1,
    '--fa-secondary-opacity': 1,
  } as CSSProperties;

  const gray = {
    '--fa-primary-color': '#A4969B',
    '--fa-secondary-color': '#A4969B',
    '--fa-primary-opacity': 1,
    '--fa-secondary-opacity': 0.5,
  } as CSSProperties;

  const grayWithBlue = {
    '--fa-primary-color': '#A4969B',
    '--fa-secondary-color': '#ADEEE3',
    '--fa-primary-opacity': 1,
    '--fa-secondary-opacity': 1,
  } as CSSProperties;

  const blue = {
    '--fa-primary-color': '#150578',
    '--fa-secondary-color': '#150578',
    '--fa-primary-opacity': 1,
    '--fa-secondary-opacity': 0.5,
  } as CSSProperties;

  switch (iconCode) {
    case '01d':
    case '01n':
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'sun-bright', family: 'duotone', style: 'solid' })}
          size={size}
          style={yellow}
        />
      );
    case '02d':
    case '02n':
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'cloud-sun', family: 'duotone', style: 'solid' })}
          size={size}
          style={yellowWithGray}
        />
      );
    case '03d':
    case '03n':
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'cloud', family: 'duotone', style: 'solid' })}
          size={size}
          style={gray}
        />
      );
    case '04d':
    case '04n':
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'clouds', family: 'duotone', style: 'solid' })}
          size={size}
          style={gray}
        />
      );
    case '09d':
    case '09n':
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'cloud-drizzle', family: 'duotone', style: 'solid' })}
          size={size}
          style={grayWithBlue}
        />
      );
    case '10d':
    case '10n':
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'cloud-showers', family: 'duotone', style: 'solid' })}
          size={size}
          style={grayWithBlue}
        />
      );
    case '11d':
    case '11n':
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'cloud-bolt', family: 'duotone', style: 'solid' })}
          size={size}
          style={yellowWithGray}
        />
      );
    case '13d':
    case '13n':
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'snowflake', family: 'duotone', style: 'solid' })}
          size={size}
          style={blue}
        />
      );
    case '50d':
    case '50n':
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'cloud-fog', family: 'duotone', style: 'solid' })}
          size={size}
          style={gray}
        />
      );
    default:
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'clouds', family: 'duotone', style: 'solid' })}
          size={size}
          style={gray}
        />
      );
  }
}

type WeatherIconProps = {
  iconCode: String
};

export default WeatherIcon;
