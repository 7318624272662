import React, {
  useEffect, useState,
} from 'react';
import { UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import { Checkbox as FlowbiteCheckbox } from 'flowbite-react';

function Checkbox({
  register, setValue, labelOn, labelOff, toggleValue,
}: ToggleSwitchProps) {
  const [checkboxValue, setCheckboxValue] = useState(toggleValue);

  useEffect(() => {
    setValue(register.name, checkboxValue.toString());
  }, [checkboxValue]);

  return (
    <div className="mb-2 flex items-center gap-2">
      <input
        type="hidden"
        {...register}
      />
      <FlowbiteCheckbox
        id={register.name}
        defaultChecked={checkboxValue}
        onChange={() => setCheckboxValue(!checkboxValue)}
      />
      <div className="text-sm font-medium text-gray-900">
        {checkboxValue ? labelOn : labelOff}
      </div>
    </div>
  );
}

type ToggleSwitchProps = {
  register: UseFormRegisterReturn<any>
  setValue: UseFormSetValue<any>
  labelOn: string
  labelOff: string
  toggleValue: boolean
};

export default Checkbox;
