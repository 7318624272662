import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomModal from './DataEntryModal';
import {
  Asset, AssetsResult, useDeleteDataEntryAssetMutation, useSearchDataEntryAssetsLazyQuery,
} from '../../../generated/gql/types';
import SearchBar from './SearchBar';
import BlankWhiteWrapper from './Wrapper';
import DataEntryRecordCreate from './DataEntryRecordCreate';
import DataEntryRecordEdit from './DataEntryRecordEdit';
import { DashboardRoute } from '../../Routes';
import {
  DataEntryAssetProperty,
  Direction, generateSearchString, getProperty, Sort,
} from '../../../AssetHelpers';
import { GoodCitySenseOrganizationIdHeader } from '../../../Global';
import DataEntryTable from './DataEntryTable';
import DataEntryButton from './DataEntryButton';

function DataEntryTableManager() {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>();
  const [editMode, setEditMode] = useState(false);
  const [sort, setSort] = useState<Sort>({ name: 'created_at', direction: Direction.DESC });
  const [selectedItems, setSelectedItems] = useState<Asset[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  const params = useParams();
  const { idTokenPayload } = useOidcIdToken();

  const [searchAssets, { data: assetData, refetch: searchAssetsRefetch, loading: assetLoading }] = useSearchDataEntryAssetsLazyQuery({
    context: {
      clientName: 'asset',
      headers: {
        [GoodCitySenseOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const [deleteAsset] = useDeleteDataEntryAssetMutation({
    context: {
      clientName: 'asset',
      headers: {
        [GoodCitySenseOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const searchAssetsForManager = (query: string) => {
    searchAssets({
      variables: {
        query,
      },
    });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= Math.ceil(assetData?.searchDataEntryAssets?.meta?.total ?? 1 / (assetData?.searchDataEntryAssets?.meta?.perPage ?? 1))) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    searchAssetsForManager(`?${generateSearchString(10, currentPage, searchQuery, sort, [])}`);
  }, [currentPage, searchQuery, sort]);

  const handleEdit = (item: Asset) => {
    setSelectedAsset(item);
    setEditMode(true);
    setOpenModal(true);
  };

  const handleNewAdd = () => {
    setEditMode(false);
    setOpenModal(true);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const getOwnedAndNotLockedAssets = (assets: Asset[]) => assets.filter((a) => a?.lockedAt === null && getProperty(a, DataEntryAssetProperty.UserId)?.value === idTokenPayload?.gcs['user-id']);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedItems(getOwnedAndNotLockedAssets(assetData?.searchDataEntryAssets?.data ?? []));
    } else {
      setSelectedItems([]);
    }
  };

  const deleteSelectedAssets = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`${t('are you sure you want to delete all selected measurements')}`)) {
      Promise.all(selectedItems.map((a) => (
        deleteAsset({
          variables: {
            assetId: a.id,
          },
        })
      ).then((_) => {
        searchAssetsRefetch();
      })));
    }
  };

  const handleSelectItem = (item: Asset) => {
    const isSelected = selectedItems.includes(item);
    const newSelectedItems = isSelected
      ? selectedItems.filter((i) => i !== item)
      : [...selectedItems, item];
    setSelectedItems(newSelectedItems);
  };

  const handleToggleModal: () => void = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (!openModal) {
      setSelectedAsset(undefined);
    }
  }, [openModal]);

  function renderModal(orgId: string) {
    if (editMode && selectedAsset) {
      return (
        <CustomModal isOpen={openModal} modalSize="7xl" onClose={handleToggleModal} header="Meting bewerken">
          <DataEntryRecordEdit
            isManager
            orgId={orgId}
            asset={selectedAsset}
            closeHandler={handleToggleModal}
            refetchQuery="SearchDataEntryAssets"
          />
        </CustomModal>
      );
    }

    return (
      <CustomModal isOpen={openModal} modalSize="7xl" onClose={handleToggleModal} header="Meting toevoegen">
        <DataEntryRecordCreate
          orgId={orgId}
          closeHandler={handleToggleModal}
          refetchQuery="SearchDataEntryAssets"
        />
      </CustomModal>
    );
  }

  return (
    <div className="pb-20">
      {params.orgId && (
        renderModal(params.orgId)
      )}

      <div className="flex justify-between items-start">
        <div className="flex items-center gap-2">
          <Link
            to={DashboardRoute()}
          >
            <div className="bg-white hover:bg-gray-200 p-1 px-2.5 rounded-lg border">
              <FontAwesomeIcon
                icon={icon({ name: 'chevron-left', family: 'classic', style: 'solid' })}
              />
            </div>
          </Link>

          <div className="mt-1">
            <p className="text-2xl font-medium text-gray-900">Overzicht beheerder</p>

            {(assetData?.searchDataEntryAssets?.meta?.total ?? 0) === 1 ? (
              <p className="text-sm font-normal text-[#686868]">
                Er is 1 meting beschikbaar
              </p>
            ) : (
              <p className="text-sm font-normal text-[#686868]">
                Er zijn
                {' '}
                {assetData?.searchDataEntryAssets?.meta?.total ?? 0}
                {' '}
                metingen beschikbaar
              </p>
            )}
          </div>
        </div>
        <div className="flex gap-5 items-center">
          <SearchBar
            onSearch={handleSearch}
            placeholder="Zoek een meting"
          />
          <DataEntryButton
            iconProp={
              <FontAwesomeIcon className="text-white group-hover:text-gcs-info" size="xl" icon={icon({ name: 'search' })} />
            }
            color="info"
          >
            Zoeken
          </DataEntryButton>
        </div>
      </div>
      <BlankWhiteWrapper>
        <div className="mb-7 flex items-center justify-between gap-3">
          <div className=" flex gap-3">
            <DataEntryButton
              onClick={handleNewAdd}
              iconProp={
                <FontAwesomeIcon className="text-gcs-info group-hover:text-white" size="xl" icon={icon({ name: 'plus-circle' })} />
              }
              color="blank"
            >
              Meting toevoegen
            </DataEntryButton>

            {selectedItems.length > 0 ? (
              <DataEntryButton
                iconProp={
                  <FontAwesomeIcon className="text-white group-hover:text-gcs-danger" size="xl" icon={icon({ name: 'trash-alt' })} />
                }
                color="danger"
                onClick={deleteSelectedAssets}
              >
                Verwijderen
              </DataEntryButton>
            ) : (
              <DataEntryButton
                disabled
                iconProp={
                  <FontAwesomeIcon className="text-white" size="xl" icon={icon({ name: 'trash-alt' })} />
                }
                color="danger"
                onClick={deleteSelectedAssets}
              >
                Verwijderen
              </DataEntryButton>
            )}
          </div>
        </div>
        <>
          <div className=" overflow-y-hidden overflow-x-auto rounded-t-md border border-[#68686880]">
            {assetLoading || !assetData ? (
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-[#68686826]" />
                <tbody className="text-left" />
              </table>
            ) : (
              <DataEntryTable
                assets={assetData?.searchDataEntryAssets}
                userId={idTokenPayload?.gcs['user-id'] ?? ''}
                handleSelectAll={handleSelectAll}
                handleEdit={handleEdit}
                handleSelectItem={handleSelectItem}
                selectedItems={selectedItems}
                setSort={setSort}
                sort={sort}
              />
            )}
          </div>
          <nav
            className=" flex items-end flex-col justify-end gap-2 pt-4"
            aria-label="Table navigation"
          >
            <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
              <li className="flex">
                <button
                  type="button"
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                  className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-r-0 rounded-l-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50"
                >
                  <svg
                    width="15"
                    height="13"
                    viewBox="0 0 15 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.21066 12.3333C5.91659 12.3333 5.63459 12.2104 5.42669 11.9919L0.991169 7.33097C0.783286 7.11246 0.666504 6.81613 0.666504 6.50715C0.666504 6.19817 0.783286 5.90184 0.991169 5.68333L5.42669 1.02239C5.52898 0.911097 5.65134 0.822327 5.78662 0.761258C5.92191 0.700189 6.06742 0.668045 6.21466 0.6667C6.36189 0.665356 6.50791 0.694838 6.64419 0.753427C6.78046 0.812016 6.90427 0.898539 7.00839 1.00795C7.1125 1.11735 7.19484 1.24745 7.2506 1.39066C7.30635 1.53386 7.33441 1.6873 7.33313 1.84202C7.33185 1.99674 7.30126 2.14964 7.24314 2.2918C7.18503 2.43396 7.10055 2.56254 6.99464 2.67003L3.3431 6.50715L6.99464 10.3443C7.14967 10.5072 7.25525 10.7148 7.29801 10.9408C7.34078 11.1668 7.31882 11.4011 7.23491 11.614C7.151 11.8269 7.00891 12.0088 6.8266 12.1369C6.64429 12.2649 6.42995 12.3333 6.21066 12.3333Z"
                      fill="#6B7280"
                    />
                    <path
                      d="M13.2107 12.3333C12.9166 12.3333 12.6346 12.2104 12.4267 11.9919L7.99117 7.33097C7.78329 7.11246 7.6665 6.81613 7.6665 6.50715C7.6665 6.19817 7.78329 5.90184 7.99117 5.68333L12.4267 1.02239C12.529 0.911097 12.6513 0.822327 12.7866 0.761258C12.9219 0.700189 13.0674 0.668045 13.2147 0.6667C13.3619 0.665356 13.5079 0.694838 13.6442 0.753427C13.7805 0.812016 13.9043 0.898539 14.0084 1.00795C14.1125 1.11735 14.1948 1.24745 14.2506 1.39066C14.3064 1.53386 14.3344 1.6873 14.3331 1.84202C14.3318 1.99674 14.3013 2.14964 14.2431 2.2918C14.185 2.43396 14.1006 2.56254 13.9946 2.67003L10.3431 6.50715L13.9946 10.3443C14.1497 10.5072 14.2552 10.7148 14.298 10.9408C14.3408 11.1668 14.3188 11.4011 14.2349 11.614C14.151 11.8269 14.0089 12.0088 13.8266 12.1369C13.6443 12.2649 13.4299 12.3333 13.2107 12.3333Z"
                      fill="#6B7280"
                    />
                  </svg>
                </button>

                <button
                  type="button"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300  hover:text-[#237D86] dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50"
                >
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.54424 11.664C5.76352 11.6639 5.97787 11.5956 6.16018 11.4675C6.34249 11.3395 6.48458 11.1575 6.56849 10.9446C6.6524 10.7317 6.67436 10.4975 6.63159 10.2715C6.58882 10.0455 6.48325 9.83789 6.32822 9.67493L2.67668 5.83781L6.32822 2.00069C6.43413 1.8932 6.51861 1.76462 6.57672 1.62246C6.63484 1.4803 6.66543 1.32739 6.66671 1.17267C6.66799 1.01796 6.63993 0.864518 6.58417 0.721315C6.52842 0.578112 6.44608 0.448011 6.34196 0.338604C6.23785 0.229197 6.11404 0.142674 5.97776 0.0840852C5.84149 0.0254962 5.69547 -0.00398602 5.54823 -0.00264155C5.401 -0.00129708 5.25549 0.0308474 5.1202 0.0919162C4.98491 0.152985 4.86256 0.241755 4.76026 0.353047L0.324747 5.01399C0.116864 5.2325 8.15392e-05 5.52883 8.15392e-05 5.83781C8.15392e-05 6.14679 0.116864 6.44311 0.324747 6.66163L4.76026 11.3226C4.96817 11.5411 5.25017 11.6639 5.54424 11.664Z"
                      fill="#6B7280"
                    />
                  </svg>
                </button>
              </li>

              {assetData?.searchDataEntryAssets?.meta?.total ? (
                <>
                  {[...Array(Math.ceil((assetData?.searchDataEntryAssets?.meta?.total ?? 1) / (assetData?.searchDataEntryAssets?.meta?.perPage ?? 1)))].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                      <li key={pageNumber}>
                        <button
                          type="button"
                          onClick={() => handlePageChange(pageNumber)}
                          className={`flex items-center justify-center px-3 h-8 leading-tight ${
                            currentPage === pageNumber
                              ? 'text-[#237D86] border border-gray-300 bg-[#D0EBEE] hover:bg-[#D0EBEE] hover:text-[#237D86]'
                              : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700'
                          } `}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    );
                  })}
                </>
              ) : (
                <li key={1}>
                  <button
                    type="button"
                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                  >
                    1
                  </button>
                </li>
              )}

              <li className="flex">
                <button
                  type="button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === Math.ceil((assetData?.searchDataEntryAssets?.meta?.total ?? 1) / (assetData?.searchDataEntryAssets?.meta?.perPage ?? 1))}
                  className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50"
                >
                  <svg
                    width="7"
                    height="13"
                    viewBox="0 0 7 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.45564 12.3333C1.23635 12.3333 1.02201 12.2649 0.8397 12.1369C0.657389 12.0088 0.515297 11.8269 0.431388 11.614C0.347479 11.4011 0.325521 11.1668 0.368287 10.9408C0.411054 10.7148 0.516627 10.5072 0.671658 10.3443L4.3232 6.50715L0.671658 2.67003C0.565749 2.56254 0.481272 2.43396 0.423156 2.2918C0.365041 2.14964 0.334451 1.99674 0.333172 1.84202C0.331892 1.6873 0.35995 1.53386 0.415705 1.39066C0.471461 1.24745 0.553798 1.11735 0.657914 1.00795C0.76203 0.898539 0.885838 0.812016 1.02212 0.753427C1.15839 0.694838 1.30441 0.665356 1.45165 0.6667C1.59888 0.668045 1.74439 0.700189 1.87968 0.761258C2.01496 0.822327 2.13732 0.911097 2.23961 1.02239L6.67513 5.68333C6.88301 5.90184 6.9998 6.19817 6.9998 6.50715C6.9998 6.81613 6.88301 7.11246 6.67513 7.33097L2.23961 11.9919C2.03171 12.2104 1.74971 12.3333 1.45564 12.3333Z"
                      fill="#6B7280"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  onClick={() => handlePageChange(Math.ceil((assetData?.searchDataEntryAssets?.meta?.total ?? 1) / (assetData?.searchDataEntryAssets?.meta?.perPage ?? 1)))}
                  disabled={currentPage === Math.ceil((assetData?.searchDataEntryAssets?.meta?.total ?? 1) / (assetData?.searchDataEntryAssets?.meta?.perPage ?? 1))}
                  className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-l-0 border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50"
                >
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.45584 12.3333C8.74991 12.3333 9.03191 12.2104 9.23982 11.9919L13.6753 7.33097C13.8832 7.11246 14 6.81613 14 6.50715C14 6.19817 13.8832 5.90184 13.6753 5.68333L9.23982 1.02239C9.13753 0.911097 9.01517 0.822327 8.87988 0.761258C8.74459 0.700189 8.59908 0.668045 8.45185 0.6667C8.30461 0.665356 8.1586 0.694838 8.02232 0.753427C7.88604 0.812016 7.76223 0.898539 7.65812 1.00795C7.554 1.11735 7.47166 1.24745 7.41591 1.39066C7.36015 1.53386 7.3321 1.6873 7.33338 1.84202C7.33465 1.99674 7.36524 2.14964 7.42336 2.2918C7.48148 2.43396 7.56595 2.56254 7.67186 2.67003L11.3234 6.50715L7.67186 10.3443C7.51683 10.5072 7.41126 10.7148 7.36849 10.9408C7.32572 11.1668 7.34768 11.4011 7.43159 11.614C7.5155 11.8269 7.65759 12.0088 7.8399 12.1369C8.02221 12.2649 8.23656 12.3333 8.45584 12.3333Z"
                      fill="#6B7280"
                    />
                    <path
                      d="M1.45584 12.3333C1.74991 12.3333 2.03191 12.2104 2.23982 11.9919L6.67533 7.33097C6.88322 7.11246 7 6.81613 7 6.50715C7 6.19817 6.88322 5.90184 6.67533 5.68333L2.23982 1.02239C2.13753 0.911097 2.01517 0.822327 1.87988 0.761258C1.74459 0.700189 1.59908 0.668045 1.45185 0.6667C1.30461 0.665356 1.1586 0.694838 1.02232 0.753427C0.886042 0.812016 0.762233 0.898539 0.658118 1.00795C0.554002 1.11735 0.471664 1.24745 0.415909 1.39066C0.360153 1.53386 0.332096 1.6873 0.333375 1.84202C0.334655 1.99674 0.365245 2.14964 0.42336 2.2918C0.481475 2.43396 0.565953 2.56254 0.671862 2.67003L4.3234 6.50715L0.671862 10.3443C0.51683 10.5072 0.411258 10.7148 0.368491 10.9408C0.325724 11.1668 0.347682 11.4011 0.431592 11.614C0.515501 11.8269 0.657593 12.0088 0.839904 12.1369C1.02221 12.2649 1.23656 12.3333 1.45584 12.3333Z"
                      fill="#6B7280"
                    />
                  </svg>
                </button>
              </li>
            </ul>
            <div className="text-sm font-normal text-gray-700 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
              Toon
              <span className="font-semibold text-gray-900 pl-1 pr-1 dark:text-white">
                {(currentPage - 1) * (assetData?.searchDataEntryAssets?.meta?.perPage ?? 1) + 1}
              </span>
              tot
              <span className="font-semibold text-gray-900 pl-1 pr-1 dark:text-white">
                {Math.min(currentPage * (assetData?.searchDataEntryAssets?.meta?.perPage ?? 1), (assetData?.searchDataEntryAssets?.data ?? []).length)}
              </span>
              van
              <span className="font-semibold text-gray-900 pl-1 dark:text-white">
                {assetData?.searchDataEntryAssets?.meta?.total ?? 0}
              </span>
            </div>
          </nav>
        </>
      </BlankWhiteWrapper>
    </div>
  );
}

export type DateEntryTableProps = {
  loading: boolean
  assets: AssetsResult | undefined,
  searchAssets: (query: string) => void
  deleteAsset: (id: string) => void
};

export default DataEntryTableManager;
