import React, { } from 'react';
import { useParams } from 'react-router-dom';
import {
  RoleOrganization,
} from '../../../generated/gql/types';
import useMyRoles from '../../hooks/useMyRoles';
import DataEntryTableManager from './DataEntryTableManager';
import DataEntryTableUser from './DataEntryTableUser';

function DataEntryApp() {
  const params = useParams();

  const myRoles = useMyRoles();
  const roles = (myRoles?.organizationRoles ?? []).find((r) => r.orgId === params.orgId)?.roles ?? [];
  const isDataEntryManager = roles.find((r) => r === RoleOrganization.OrgDataEntryManager);

  if (isDataEntryManager) {
    return <DataEntryTableManager />;
  }

  return <DataEntryTableUser />;
}
export default DataEntryApp;
