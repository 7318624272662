import React, { ReactNode } from 'react';

function BoxElement({ title, description, children } : BoxElementProps) {
  return (
    <div className="rounded-lg border border-gray-400 bg-white shadow-md mb-8">
      <div className="support p-5">
        { title && (
          <div>
            <h3 className="mt-2 mb-4 text-2xl font-bold text-gray-900">{title}</h3>
          </div>
        )}

        { description && (
          <div className="h-full mb-5">
            <p className="font-normal text-gray-700 dark:text-gray-400">
              {description}
            </p>
          </div>
        )}

        {children}
      </div>
    </div>
  );
}

export type BoxElementProps = {
  title?: string
  description?: string
  children: ReactNode
};

export default BoxElement;
