import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DataEntryRoute } from '../../Routes';
import ArrowRight from '../../elements/ArrowRight';

function DataEntryCard({ orgId } : DataEntryCardProps) {
  const iconStyle = {
    '--fa-primary-color': '#247D7F',
    '--fa-secondary-color': '#A6C5D4',
    '--fa-primary-opacity': 1.0,
    '--fa-secondary-opacity': 1.0,
  } as CSSProperties;

  return (
    <div className="gcscard flex rounded-lg border border-gray-400 bg-white shadow-md flex-col w-80 shrink-0 grow mb-4 justify-top">
      <div className="flex h-full flex-col justify-top">
        <div className="flex bg-green-50 rounded-t-lg p-5 border-b-4 border-wc-green min-h-[116px]">
          <div className="flex flex-col flex-grow">
            <h4 className="inline-left mt-2 text-xl font-bold text-gray-900">
              Beheer je metingen voor
            </h4>
            <div className="capitalize-first">{(orgId ?? '').replaceAll('-', ' ')}</div>
          </div>
          <div className="flex inline-right items-center">
            <FontAwesomeIcon
              icon={icon({ name: 'flask', family: 'classic', style: 'duotone' })}
              size="4x"
              style={iconStyle}
              className="h-[50px] self-center inline-right"
            />
          </div>
        </div>

        <div className="h-full p-5 mb-5">
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Beheer hier jouw metingen, voeg nieuwe metingen toe of update bestaande metingen met nieuwe resultaten.
            Voor meer informatie informeer bij je docent!
          </p>
        </div>

        <Link
          to={DataEntryRoute(orgId)}
          className="btn-card border group flex h-min items-center justify-center p-0.5 m-5 text-center font-bold rounded-md text-sm focus:z-10"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            Beheer metingen
            <ArrowRight />
          </span>
        </Link>

      </div>
    </div>
  );
}

type DataEntryCardProps = {
  orgId: string
};

export default DataEntryCard;
