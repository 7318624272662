import React from 'react';
import { useParams } from 'react-router-dom';
import { getProperty, latLongFromProperty, PientereTuinenAssetProperty } from '../../../AssetHelpers';
import Loader from '../../Loader';
import usePientereTuinenAssetBySerial from '../../hooks/usePientereTuinenAssetBySerial';
import WeatherForecast from './WeatherForecast';

function WeatherApp() {
  const params = useParams();
  const { asset, loading: assetLoading } = usePientereTuinenAssetBySerial({ serial: params.serial ?? '' });

  if (assetLoading || !asset) {
    return <Loader />;
  }

  const assetCoordinates = latLongFromProperty(
    getProperty(asset, PientereTuinenAssetProperty.Coordinates),
  );

  if (!assetCoordinates) {
    return null;
  }

  return (
    <WeatherForecast coordinates={assetCoordinates} />
  );
}

export default WeatherApp;
