import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
function BlankWhiteWrapper({ children }: Props) {
  return (
    <div className="px-[34px] py-[32px] bg-white rounded-xl">
      {children}
    </div>
  );
}
export default BlankWhiteWrapper;
