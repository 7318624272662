import React, { useEffect, useRef, useState } from 'react';
import { LatLng, LatLngBounds } from 'leaflet';
import {
  FieldErrors, UseFormRegister, UseFormSetValue, UseFormUnregister,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Label, Textarea, TextInput } from 'flowbite-react';
import { isValid, parse } from 'date-fns';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOidcIdToken } from '@axa-fr/react-oidc';
import {
  MapContainer, Marker, Popup, TileLayer, ZoomControl,
} from 'react-leaflet';
import DataEntryButton from './DataEntryButton';
import {
  Asset,
  CreateAssetCommand,
  UpdateAssetCommand,
  useGetDataEntrySchemeQuery,
  useGetPientereTuinenAssetBySerialLazyQuery,
} from '../../../generated/gql/types';
import { GoodCitySenseOrganizationIdHeader } from '../../../Global';
import Dropdown from '../pientere-tuinen/Dropdown';
import {
  AssetWithCoordinates,
  DataEntryAssetProperty,
  getProperty, latLongFromProperty,
  PientereTuinenAssetProperty,
  validationStringToOptionList,
} from '../../../AssetHelpers';

type DataEntryFormProps = {
  asset: Asset | undefined; // Define the type of the asset prop
  register: UseFormRegister<UpdateAssetCommand | CreateAssetCommand>
  unregister: UseFormUnregister<UpdateAssetCommand | CreateAssetCommand>
  errors: FieldErrors<UpdateAssetCommand | CreateAssetCommand>
  setValue: UseFormSetValue<UpdateAssetCommand | CreateAssetCommand>
  disabled: boolean
};

function DataEntryForm({
  asset, register, unregister, errors, setValue, disabled,
}: DataEntryFormProps) {
  const [isMeasurementFixedValues, setIsMeasurementFixedValues] = useState<boolean>(asset ? !!getProperty(asset, DataEntryAssetProperty.NitrogenValue)?.value : false);
  const [sensorAsset, setSensorAsset] = useState<AssetWithCoordinates | undefined>(undefined);

  const params = useParams();
  const { t } = useTranslation();
  const sensorIdSearchInputRef = useRef<HTMLInputElement>(null);
  const { idTokenPayload } = useOidcIdToken();
  const {
    data: scheme,
  } = useGetDataEntrySchemeQuery({
    context: {
      clientName: 'asset',
      headers: {
        [GoodCitySenseOrganizationIdHeader]: params.orgId,
      },
    },
  });

  const [getSensorAssetBySerial, {
    data: sensorAssetData, loading: sensorAssetLoading, error: sensorAssetError,
  }] = useGetPientereTuinenAssetBySerialLazyQuery({
    context: {
      clientName: 'asset',
      headers: {
        [GoodCitySenseOrganizationIdHeader]: params.orgId,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (asset !== undefined) {
      const sensorId = getProperty(asset, DataEntryAssetProperty.PientereTuinenSerial)?.value;

      if (sensorId && !sensorAssetLoading) {
        getSensorAssetBySerial({
          variables: {
            serial: sensorId,
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    if (sensorAssetData?.pientereTuinenAssetBySerial?.data !== undefined && !sensorAssetLoading) {
      setSensorAsset(
        {
          asset: sensorAssetData?.pientereTuinenAssetBySerial?.data,
          location: latLongFromProperty(
            getProperty(sensorAssetData?.pientereTuinenAssetBySerial?.data, PientereTuinenAssetProperty.Coordinates),
          ),
        } as AssetWithCoordinates,
      );
    }
  }, [sensorAssetData, sensorAssetLoading, sensorAssetError]);

  useEffect(() => {
    if (sensorAsset && sensorIdSearchInputRef != null && sensorIdSearchInputRef.current != null) {
      const sensorSerialId = getProperty(sensorAsset.asset, PientereTuinenAssetProperty.SensorId)?.value ?? '';
      sensorIdSearchInputRef.current.value = sensorSerialId;
      setValue('properties.11.value', sensorSerialId);
    }
  }, [sensorAsset]);

  const schemeData = scheme?.scheme?.data;

  const handleCheckboxChange = () => {
    setIsMeasurementFixedValues(!isMeasurementFixedValues);
  };

  const handleSensorData = () => {
    if (sensorIdSearchInputRef != null && sensorIdSearchInputRef.current != null && sensorIdSearchInputRef.current.value !== '') {
      setSensorAsset(undefined);
      getSensorAssetBySerial({
        variables: {
          serial: sensorIdSearchInputRef.current.value,
        },
      });
    }
  };

  function mapDateForDatePicker(date: string) {
    const validUntil = parse(date, 'y-MM-dd', new Date());

    if (isValid(validUntil)) {
      return date;
    }

    return undefined;
  }

  // Unregisters an input
  function unregisterPropertyInput(index: number) {
    unregister(`properties.${index}.id`);
    unregister(`properties.${index}.type`);
    unregister(`properties.${index}.key`);
    unregister(`properties.${index}.value`);
  }

  // Resets rating / value form inputs on change
  useEffect(() => {
    unregisterPropertyInput(5);
    unregisterPropertyInput(7);
    unregisterPropertyInput(9);
  }, [isMeasurementFixedValues]);

  function renderPropertyInputMeta(index: number, type: DataEntryAssetProperty) {
    const property = asset ? getProperty(asset, type) : undefined;

    if (property) {
      return (
        <>
          <input type="hidden" {...register(`properties.${index}.id`, { value: property.id })} />
          <input type="hidden" {...register(`properties.${index}.type`, { value: type })} />
          <input type="hidden" {...register(`properties.${index}.key`, { value: type })} />
        </>
      );
    }

    return (
      <>
        <input type="hidden" {...register(`properties.${index}.type`, { value: type })} />
        <input type="hidden" {...register(`properties.${index}.key`, { value: type })} />
      </>
    );
  }

  return (
    <>
      <div>
        <p className="text-[18px] font-medium">Projectgegevens</p>
      </div>
      {renderPropertyInputMeta(0, DataEntryAssetProperty.UserId)}
      <input
        type="hidden"
        {...register('properties.0.value', { value: asset ? getProperty(asset, DataEntryAssetProperty.UserId)?.value : idTokenPayload?.gcs['user-id'] })}
      />
      <div className="block">
        <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
          <Label
            color={errors?.properties?.[1] !== undefined ? 'failure' : 'gray'}
            value="Projectnaam"
            htmlFor="properties.1.value"
          />
        </div>
        {renderPropertyInputMeta(1, DataEntryAssetProperty.ProjectName)}
        <Dropdown
          disabled={disabled}
          hasError={errors?.properties?.[1] !== undefined}
          register={register('properties.1.value', {
            disabled,
            required: true,
            pattern: /^((?!undefined).)*$/,
            value: asset ? getProperty(asset, DataEntryAssetProperty.ProjectName)?.value ?? 'undefined' : 'undefined',
          })}
          options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === DataEntryAssetProperty.ProjectName)?.validation ?? '', t)}
        />
      </div>
      <div className="grid-flow-col grid gap-8">
        <div>
          <div className="block">
            <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
              <Label
                color={errors?.properties?.[2] !== undefined ? 'failure' : 'gray'}
                value="Datum monster"
                htmlFor="properties.2.value"
              />
            </div>
          </div>
          {renderPropertyInputMeta(2, DataEntryAssetProperty.SoilExtractionDate)}
          <TextInput
            color={errors?.properties?.[2] !== undefined ? 'failure' : 'gray'}
            type="date"
            disabled={disabled}
            placeholder="Datum monster"
            defaultValue={mapDateForDatePicker(asset ? getProperty(asset, DataEntryAssetProperty.SoilExtractionDate)?.value ?? new Date().toString() : new Date().toString())}
            {...register('properties.2.value', { required: true })}
          />
        </div>
        <div className="block">
          <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
            <Label
              color={errors?.properties?.[3] !== undefined ? 'failure' : 'gray'}
              value="Status"
              htmlFor="properties.3.value"
            />
          </div>

          {renderPropertyInputMeta(3, DataEntryAssetProperty.ReviewStatus)}
          {asset?.lockedAt != null ? (
            <>
              <input
                type="hidden"
                {...register('properties.3.value', { value: asset ? getProperty(asset, DataEntryAssetProperty.ReviewStatus)?.value ?? 'undefined' : 'undefined' })}
              />

              <div className="items-center pt-2.5">
                <FontAwesomeIcon className="text-gcs-success" size="lg" icon={icon({ name: 'check-circle' })} />
                {' '}
                Goedgekeurd
              </div>
            </>
          ) : (
            <Dropdown
              disabled={disabled}
              hasError={errors?.properties?.[3] !== undefined}
              register={register('properties.3.value', {
                disabled,
                required: true,
                pattern: /^((?!undefined).)*$/,
                value: asset ? getProperty(asset, DataEntryAssetProperty.ReviewStatus)?.value ?? 'undefined' : 'undefined',
              })}
              options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === DataEntryAssetProperty.ReviewStatus)?.validation ?? '', t)}
            />
          )}
        </div>
      </div>
      <div className="pb-1">
        <div className="block">
          <div className="mb-2 text-[16px] font-normal block text-[#686868]">
            <Label
              color={errors?.properties?.[4] !== undefined ? 'failure' : 'gray'}
              htmlFor="properties.4.value"
              value="Omschrijving (optioneel)"
            />
          </div>
        </div>
        {renderPropertyInputMeta(4, DataEntryAssetProperty.Description)}
        <Textarea
          disabled={disabled}
          theme={{ base: 'text-sm rounded-lg w-full disabled:cursor-not-allowed disabled:opacity-50' }}
          color={errors?.properties?.[4] !== undefined ? 'failure' : 'gray'}
          placeholder="Tijdelijke aanduiding"
          {...register('properties.4.value', {
            value: asset ? getProperty(asset, DataEntryAssetProperty.Description)?.value ?? '' : '',
            maxLength: 255,
          })}
        />
      </div>

      <hr className="pb-3" />

      <div className="flex justify-start items-center gap-8">
        <div className="text-[18px] font-medium">Meetgegevens</div>
        <label
          style={{ boxShadow: '0px 0px 4px 0px #00000040' }}
          htmlFor="togle"
          className=" relative inline-flex cursor-pointer select-none items-center justify-center rounded-lg bg-white p-1"
        >
          <input
            id="togle"
            type="checkbox"
            className="sr-only"
            checked={isMeasurementFixedValues}
            onChange={handleCheckboxChange}
          />
          <span
            className={`flex items-center w-20 justify-center rounded-lg py-2 text-sm  text-center font-medium ${
              isMeasurementFixedValues ? 'text-white bg-[#237D86]' : 'bg-white text-[#7E818C]'
            }`}
          >
            Waarde
          </span>
          <span
            className={`flex items-center justify-center w-20 rounded-lg py-2 text-sm text-center font-medium ${
              !isMeasurementFixedValues ? 'text-white bg-[#237D86]' : 'bg-white text-[#7E818C]'
            }`}
          >
            Lijst
          </span>
        </label>
      </div>
      {/* Toggle off data starts here */}
      {
        isMeasurementFixedValues ? (
          <>
            <div className="grid-flow-col grid gap-8">
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-1 text-[#686868]">
                  <Label
                    color={errors?.properties?.[5] !== undefined ? 'failure' : 'gray'}
                    value="Stikstofwaarde (N)"
                    htmlFor="properties.5.value"
                  />
                  <FontAwesomeIcon
                    size="xs"
                    icon={icon({ name: 'exclamation-circle', family: 'classic', style: 'regular' })}
                  />
                </div>

                {renderPropertyInputMeta(5, DataEntryAssetProperty.NitrogenValue)}
                <TextInput
                  disabled={disabled}
                  color={errors?.properties?.[5] !== undefined ? 'failure' : 'gray'}
                  type="number"
                  step=".01"
                  placeholder="Stikstofwaarde (N)"
                  {...register('properties.5.value', { value: asset ? getProperty(asset, DataEntryAssetProperty.NitrogenValue)?.value ?? '' : '' })}
                />
              </div>
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
                  <Label
                    color={errors?.properties?.[6] !== undefined ? 'failure' : 'gray'}
                    value="PH waarde"
                    htmlFor="properties.6.value"
                  />
                </div>

                {renderPropertyInputMeta(6, DataEntryAssetProperty.PHValue)}
                <TextInput
                  disabled={disabled}
                  color={errors?.properties?.[6] !== undefined ? 'failure' : 'gray'}
                  type="number"
                  step=".01"
                  placeholder="PH waarde"
                  {...register('properties.6.value', { value: asset ? getProperty(asset, DataEntryAssetProperty.PHValue)?.value ?? '' : '' })}
                />
              </div>
            </div>
            <div className="grid-flow-col grid gap-8">
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-1 text-[#686868]">
                  <Label
                    color={errors?.properties?.[7] !== undefined ? 'failure' : 'gray'}
                    value="Kaliumwaarde (K)"
                    htmlFor="properties.7.value"
                  />
                  <FontAwesomeIcon
                    size="xs"
                    icon={icon({ name: 'exclamation-circle', family: 'classic', style: 'regular' })}
                  />
                </div>
                {renderPropertyInputMeta(7, DataEntryAssetProperty.PotassiumValue)}
                <TextInput
                  disabled={disabled}
                  color={errors?.properties?.[7] !== undefined ? 'failure' : 'gray'}
                  type="number"
                  step=".01"
                  placeholder="Kaliumwaarde (K)"
                  {...register('properties.7.value', { value: asset ? getProperty(asset, DataEntryAssetProperty.PotassiumValue)?.value ?? '' : '' })}
                />
              </div>
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
                  <Label
                    color={errors?.properties?.[8] !== undefined ? 'failure' : 'gray'}
                    value="% Organisch materiaal"
                    htmlFor="properties.8.value"
                  />
                </div>
                {renderPropertyInputMeta(8, DataEntryAssetProperty.OrganicMaterial)}
                <TextInput
                  disabled={disabled}
                  color={errors?.properties?.[8] !== undefined ? 'failure' : 'gray'}
                  type="number"
                  step=".01"
                  placeholder="% Organisch materiaal"
                  {...register('properties.8.value', { value: asset ? getProperty(asset, DataEntryAssetProperty.OrganicMaterial)?.value ?? '' : '' })}
                />
              </div>
            </div>
            <div className="grid-flow-col grid gap-8 pb-2">
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-1 text-[#686868]">
                  <Label
                    color={errors?.properties?.[9] !== undefined ? 'failure' : 'gray'}
                    value="Fosforwaarde (P)"
                    htmlFor="properties.9.value"
                  />
                  <FontAwesomeIcon
                    size="xs"
                    icon={icon({ name: 'exclamation-circle', family: 'classic', style: 'regular' })}
                  />
                </div>

                {renderPropertyInputMeta(9, DataEntryAssetProperty.phosphorusValue)}
                <TextInput
                  disabled={disabled}
                  color={errors?.properties?.[9] !== undefined ? 'failure' : 'gray'}
                  type="number"
                  step=".01"
                  placeholder="Fosforwaarde (P)"
                  {...register('properties.9.value', { value: asset ? getProperty(asset, DataEntryAssetProperty.phosphorusValue)?.value ?? '' : '' })}
                />
              </div>
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
                  <Label
                    color={errors?.properties?.[10] !== undefined ? 'failure' : 'gray'}
                    value="Grondsoort"
                    htmlFor="properties.10.value"
                  />
                </div>
                {renderPropertyInputMeta(10, DataEntryAssetProperty.SoilType)}
                <Dropdown
                  disabled={disabled}
                  hasError={errors?.properties?.[10] !== undefined}
                  register={register('properties.10.value', {
                    disabled,
                    required: true,
                    pattern: /^((?!undefined).)*$/,
                    value: asset ? getProperty(asset, DataEntryAssetProperty.SoilType)?.value ?? 'undefined' : 'undefined',
                  })}
                  options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === DataEntryAssetProperty.SoilType)?.validation ?? '', t)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="grid-flow-col grid gap-8">
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
                  <Label
                    color={errors?.properties?.[5] !== undefined ? 'failure' : 'gray'}
                    value="Stikstof-rating (N)"
                    htmlFor="properties.5.value"
                  />
                  <FontAwesomeIcon
                    size="xs"
                    icon={icon({ name: 'exclamation-circle', family: 'classic', style: 'regular' })}
                  />
                </div>

                {renderPropertyInputMeta(5, DataEntryAssetProperty.NitrogenRating)}
                <Dropdown
                  disabled={disabled}
                  hasError={errors?.properties?.[5] !== undefined}
                  register={register('properties.5.value', {
                    disabled,
                    pattern: /^((?!undefined).)*$/,
                    value: asset ? getProperty(asset, DataEntryAssetProperty.NitrogenRating)?.value ?? 'undefined' : 'undefined',
                  })}
                  options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === DataEntryAssetProperty.NitrogenRating)?.validation ?? '', t)}
                />
              </div>
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
                  <Label
                    color={errors?.properties?.[6] !== undefined ? 'failure' : 'gray'}
                    value="PH waarde"
                    htmlFor="properties.6.value"
                  />
                  <FontAwesomeIcon
                    size="xs"
                    icon={icon({ name: 'exclamation-circle', family: 'classic', style: 'regular' })}
                  />

                </div>
                {renderPropertyInputMeta(6, DataEntryAssetProperty.PHValue)}
                <TextInput
                  disabled={disabled}
                  color={errors?.properties?.[6] !== undefined ? 'failure' : 'gray'}
                  type="number"
                  step=".01"
                  placeholder="PH waarde"
                  {...register('properties.6.value', { value: asset ? getProperty(asset, DataEntryAssetProperty.PHValue)?.value ?? '' : '' })}
                />
              </div>
            </div>
            <div className="grid-flow-col grid gap-8">
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
                  <Label
                    color={errors?.properties?.[7] !== undefined ? 'failure' : 'gray'}
                    value="Kalium-rating (K)"
                    htmlFor="properties.7.value"
                  />
                  <FontAwesomeIcon
                    size="xs"
                    icon={icon({ name: 'exclamation-circle', family: 'classic', style: 'regular' })}
                  />
                </div>

                {renderPropertyInputMeta(7, DataEntryAssetProperty.PotassiumRating)}
                <Dropdown
                  disabled={disabled}
                  hasError={errors?.properties?.[7] !== undefined}
                  register={register('properties.7.value', {
                    disabled,
                    pattern: /^((?!undefined).)*$/,
                    value: asset ? getProperty(asset, DataEntryAssetProperty.PotassiumRating)?.value ?? 'undefined' : 'undefined',
                  })}
                  options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === DataEntryAssetProperty.PotassiumRating)?.validation ?? '', t)}
                />
              </div>
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
                  <Label
                    color={errors?.properties?.[8] !== undefined ? 'failure' : 'gray'}
                    value="% Organisch materiaal"
                    htmlFor="properties.8.value"
                  />
                  <FontAwesomeIcon
                    size="xs"
                    icon={icon({ name: 'exclamation-circle', family: 'classic', style: 'regular' })}
                  />
                </div>
                {renderPropertyInputMeta(8, DataEntryAssetProperty.OrganicMaterial)}
                <TextInput
                  disabled={disabled}
                  color={errors?.properties?.[8] !== undefined ? 'failure' : 'gray'}
                  type="number"
                  step=".01"
                  placeholder="% Organisch materiaal"
                  {...register('properties.8.value', { value: asset ? getProperty(asset, DataEntryAssetProperty.OrganicMaterial)?.value ?? '' : '' })}
                />
              </div>
            </div>
            <div className="grid-flow-col grid gap-8 pb-2">
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
                  <Label
                    color={errors?.properties?.[9] !== undefined ? 'failure' : 'gray'}
                    value="Fosfor-rating (P)"
                    htmlFor="properties.9.value"
                  />
                  <FontAwesomeIcon
                    size="xs"
                    icon={icon({ name: 'exclamation-circle', family: 'classic', style: 'regular' })}
                  />
                </div>
                {renderPropertyInputMeta(9, DataEntryAssetProperty.phosphorusRating)}
                <Dropdown
                  disabled={disabled}
                  hasError={errors?.properties?.[9] !== undefined}
                  register={register('properties.9.value', {
                    disabled,
                    pattern: /^((?!undefined).)*$/,
                    value: asset ? getProperty(asset, DataEntryAssetProperty.phosphorusRating)?.value ?? 'undefined' : 'undefined',
                  })}
                  options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === DataEntryAssetProperty.phosphorusRating)?.validation ?? '', t)}
                />
              </div>
              <div className="block">
                <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
                  <Label
                    color={errors?.properties?.[10] !== undefined ? 'failure' : 'gray'}
                    value="Grondsoort"
                    htmlFor="properties.10.value"
                  />
                  <FontAwesomeIcon
                    size="xs"
                    icon={icon({ name: 'exclamation-circle', family: 'classic', style: 'regular' })}
                  />
                </div>
                {renderPropertyInputMeta(10, DataEntryAssetProperty.SoilType)}
                <Dropdown
                  disabled={disabled}
                  hasError={errors?.properties?.[10] !== undefined}
                  register={register('properties.10.value', {
                    disabled,
                    required: true,
                    pattern: /^((?!undefined).)*$/,
                    value: asset ? getProperty(asset, DataEntryAssetProperty.SoilType)?.value ?? 'undefined' : 'undefined',
                  })}
                  options={validationStringToOptionList(schemeData?.propertyTypes?.find((p) => p.name === DataEntryAssetProperty.SoilType)?.validation ?? '', t)}
                />
              </div>
            </div>
          </>
        )
      }

      <hr className="pb-2" />
      <div className="flex justify-start gap-10">
        <p className="text-[18px] font-medium">Sensorinformatie</p>
      </div>
      <div className="grid-cols-2 grid items-end gap-8">
        <div className="block">
          <div className="mb-2 text-[16px] font-normal flex items-center justify-start gap-2 text-[#686868]">
            <Label
              value="Pientere Tuinen Sensor"
              htmlFor="sensor-id-search-input"
            />
          </div>
          <TextInput
            disabled={disabled}
            id="sensor-id-search-input"
            className="left-0 flex-grow border border-none"
            placeholder="Voer sensor-ID in"
            ref={sensorIdSearchInputRef}
          />
        </div>
        <div>
          {!sensorAssetLoading ? (
            <DataEntryButton onClick={handleSensorData} disabled={disabled} color="info">
              Sensor informatie ophalen
            </DataEntryButton>
          ) : (
            <DataEntryButton disabled>
              Sensor informatie ophalen
              <FontAwesomeIcon
                spin
                icon={icon({ name: 'sync', family: 'classic', style: 'regular' })}
              />
            </DataEntryButton>
          )}
        </div>
      </div>
      {
        (!sensorAssetLoading && sensorAsset?.location && getProperty(sensorAsset.asset, PientereTuinenAssetProperty.SensorId)?.value !== undefined) && (
          <>
            <div className="flex mt-5 justify-between items-center gap-8">
              {renderPropertyInputMeta(11, DataEntryAssetProperty.PientereTuinenSerial)}
              <input
                type="hidden"
                {...register('properties.11.value', {
                  value: getProperty(sensorAsset.asset, PientereTuinenAssetProperty.SensorId)?.value,
                })
                }
              />
              <div>
                <p className="text-[16px] text-[#686868]">Latitude</p>
                <p className="font-medium text-gray-500">
                  {sensorAsset.location.lat}
                </p>
              </div>
              <div className="flex justify-between items-center gap-16">
                <div>
                  <p className="text-[16px] text-[#686868]">Longitude</p>
                  <p className="font-medium text-gray-500">
                    {sensorAsset.location.lng}
                  </p>
                </div>
              </div>
              <a href="mailto:support@goodcitysense.nl">
                <div className="border px-4 rounded-lg flex items-center gap-2 bg-[#237D861A] text-[#237D86] py-2.5 shadow-lg hover:text-white hover:bg-[#237D86] border-gray-400 text-sm font-medium">
                  <FontAwesomeIcon
                    size="lg"
                    icon={icon({ name: 'envelope', family: 'classic', style: 'regular' })}
                  />
                  Mail support
                </div>
              </a>
            </div>
            <div className="block mb-3">
              <MapContainer
                style={{ height: '300px' }}
                center={sensorAsset.location}
                zoom={9}
                minZoom={9}
                scrollWheelZoom
                zoomControl={false}
                maxBounds={new LatLngBounds(
                  new LatLng(50.6309883, 3.0625035),
                  new LatLng(53.7181849, 7.2928109),
                )}
              >
                <ZoomControl position="topleft" />

                <TileLayer
                  attribution=""
                  url={`${process.env.REACT_APP_WMTS_URL}/topoplus_achtergrond/wm_19/{z}/{x}/{y}.jpeg`}
                />

                <Marker
                  position={sensorAsset.location}
                >
                  <Popup minWidth={90}>
                    <span>
                      Locatie van de Pientere Tuinen sensor
                    </span>
                  </Popup>
                </Marker>

              </MapContainer>
            </div>
          </>
        )
      }
      {(sensorAssetError || sensorAssetData?.pientereTuinenAssetBySerial === null) && (
      <div className="w-full bg-red-100 rounded-lg relative text-center p-5">
        <svg
          className="absolute cursor-pointer top-3 right-3"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.1889 6.01057L11.5997 1.59973C11.6793 1.52286 11.7428 1.43091 11.7865 1.32924C11.8302 1.22757 11.8531 1.11822 11.8541 1.00757C11.8551 0.896917 11.834 0.787184 11.7921 0.684771C11.7502 0.582357 11.6883 0.489314 11.6101 0.41107C11.5318 0.332826 11.4388 0.270948 11.3364 0.229047C11.2339 0.187147 11.1242 0.166062 11.0136 0.167024C10.9029 0.167985 10.7936 0.190974 10.6919 0.234648C10.5902 0.278322 10.4983 0.341807 10.4214 0.421399L6.01057 4.83223L1.59973 0.421399C1.44256 0.269601 1.23206 0.185605 1.01356 0.187504C0.795067 0.189402 0.586057 0.277043 0.43155 0.43155C0.277043 0.586057 0.189402 0.795067 0.187504 1.01356C0.185605 1.23206 0.269601 1.44256 0.421399 1.59973L4.83223 6.01057L0.421399 10.4214C0.341807 10.4983 0.278322 10.5902 0.234648 10.6919C0.190974 10.7936 0.167985 10.9029 0.167024 11.0136C0.166062 11.1242 0.187147 11.2339 0.229047 11.3364C0.270948 11.4388 0.332826 11.5318 0.41107 11.6101C0.489314 11.6883 0.582357 11.7502 0.684771 11.7921C0.787184 11.834 0.896917 11.8551 1.00757 11.8541C1.11822 11.8531 1.22757 11.8302 1.32924 11.7865C1.43091 11.7428 1.52286 11.6793 1.59973 11.5997L6.01057 7.1889L10.4214 11.5997C10.5786 11.7515 10.7891 11.8355 11.0076 11.8336C11.2261 11.8317 11.4351 11.7441 11.5896 11.5896C11.7441 11.4351 11.8317 11.2261 11.8336 11.0076C11.8355 10.7891 11.7515 10.5786 11.5997 10.4214L7.1889 6.01057Z"
            fill="#C81E1E"
          />
        </svg>

        <p className="text-red-700 text-[16px] font-semibold">
          Sensor niet gevonden
        </p>
        <p className="text-red-700 text-[15px] font-normal">
          Het lijkt erop dat het ingevoerde sensor id niet bestaat,
          controleer het sensor id en probeer het opnieuw
        </p>
      </div>
      )}
    </>
  );
}

export default DataEntryForm;
